import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-050da6f4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-flex my-rows my-nowrap"
};
const _hoisted_2 = {
  class: "my-bot-body my-line-b"
};
const _hoisted_3 = {
  class: "my-bot-top my-flex my-rows my-wrap my-space-between my-nowrap my-align-items-center"
};
const _hoisted_4 = {
  class: "bot-name"
};
const _hoisted_5 = {
  class: "font-14 font-w text-black-1"
};
const _hoisted_6 = {
  class: "my-bot-top my-flex my-rows my-wrap my-space-between my-nowrap my-align-items-center"
};
const _hoisted_7 = {
  class: "bot-time text-black-3"
};
const _hoisted_8 = {
  key: 0,
  class: "font-14 text-black-3"
};
const _hoisted_9 = {
  key: 1,
  class: "font-14 text-green-1"
};
const _hoisted_10 = {
  key: 2,
  class: "font-14 text-red"
};
import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/index.js';
import { useRouter } from 'vue-router';
import { getDrawList } from '@/api/index.js';
export default {
  __name: 'drawlist',
  setup(__props) {
    const userStore = useUserStore();
    const router = useRouter();
    const empty = ref(false);
    const is_auth = ref(false);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    let senddata = {
      page: 1,
      limit: 20,
      user_addr: userStore.userInfo.user_addr
    };
    const onLoad = () => {
      getDrawList(senddata).then(data => {
        let res = data.data.data.data;
        res.forEach(item => {
          item.num = parseFloat(item.amount).toFixed(3);
        });
        console.log(res.length);
        if (res.length == 0) {
          finished.value = true;
          loading.value = false;
        } else {
          list.value.push(...res);
          finished.value = false;
          loading.value = false;
          senddata.page++;
        }

        // refreshing.value = false;
        // finished.value = true;
      });
    };
    watch(list, (newlist, oldlist) => {
      if (newlist.length < 1) {
        empty.value = true;
      } else {
        empty.value = false;
      }
    });
    onMounted(() => {});
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    const onClickLeft = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        fixed: true,
        placeholder: true,
        title: "Withdrawal Record",
        "left-text": "",
        "left-arrow": "",
        onClickLeft: onClickLeft,
        border: false
      }), _createVNode(_component_van_pull_refresh, {
        modelValue: refreshing.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => refreshing.value = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: loading.value,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
          finished: finished.value,
          "finished-text": " ",
          "loading-text": "loading...",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
            return _openBlock(), _createBlock(_component_van_cell, {
              key: item,
              border: false
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.order_id), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(item.num), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.create_time), 1), item.state == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Wait")) : item.state == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Success")) : (_openBlock(), _createElementBlock("div", _hoisted_10, "Fail"))])])])]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"]), empty.value ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        description: "Empty"
      })) : _createCommentVNode("", true)], 64);
    };
  }
};